export default {
  "app": {
    "로-컬": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로-컬"])},
    "로-컬 숨은 여행지 찾기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로-컬 ｜ 숨은 여행지 찾기"])},
    "등록하기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록하기"])},
    "취소하기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소하기"])}
  },
  "navbar": {
    "저장": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
    "공지": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지"])},
    "건의": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["건의"])},
    "로그인": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "로그아웃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
    "회원가입": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "즐겨찾기된 장소가 없습니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기된 장소가 없습니다"])}
  },
  "auth": {
    "이메일": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "닉네임": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
    "비밀번호": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "계정이 없으신가요? 회원가입": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 없으신가요? 회원가입"])},
    "이미 계정이 있으신가요? 로그인": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 계정이 있으신가요? 로그인"])}
  },
  "tag": {
    "음식": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음식"])},
    "관광": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관광"])},
    "모험": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모험"])},
    "체험": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체험"])},
    "숙소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소"])},
    "축제": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["축제"])}
  },
  "search": {
    "검색어를 입력하세요": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어를 입력하세요..."])},
    "검색된 장소가 없습니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색된 장소가 없습니다"])}
  },
  "side-tab": {
    "로딩": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로딩..."])}
  },
  "feedback": {
    "여기에 글을 작성하세요": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에 글을 작성하세요..."])},
    "익명의 모험가": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From. 익명의 모험가"])},
    "취소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "보내기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기"])}
  },
  "new-place": {
    "취소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "이전": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전"])},
    "다음": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음"])},
    "완료": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
    "사진": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사진"])},
    "이름": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "설명": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
    "사진 3장 이하로!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사진 3장 이하로!"])},
    "필수 입력입니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수 입력입니다"])}
  },
  "import-place": {
    "가져오기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가져오기"])},
    "취소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "선택": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
    "구글 지도에서 보기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 지도에서 보기"])}
  },
  "toast": {
    "로그인 실패!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 실패!"])},
    "회원가입 실패!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 실패!"])},
    "로그인 성공": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 성공"])},
    "회원가입 완료": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 완료"])},
    "로그인이 필요합니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인이 필요합니다"])},
    "건의할 내용을 입력해주세요": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["건의할 내용을 입력해주세요"])},
    "피드백 감사합니다!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 감사합니다!"])}
  }
}